<script>
import Layout from "@/router/layouts/store";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";
import Qty from "@/components/widgets/store-qty";
import Shipping from "@/components/widgets/store-shipping";

export default {
  locales: {
    pt: {
      'Cart': 'Carrinho',
    },
    es: {
      'Cart': 'Cesta',
    }
  },
  components: { Layout, VclList, Qty, Shipping },
  data() {
    return {
      cart: {
        items: {
          loading: true,
          total: 0,
          list: null
        },
        subtotal: 'R$ 0,00',
        shipping: {
          method: '-',
          value: '-',
        },
        total: 'R$ 0,00'
      },
      checkout: {
        loading: false,
        errored: false,
        empty: false,
      },
    };
  },
  methods: {
    getCart() {
      this.cart = this.$children[0].cart
    },
    checkoutCart() {
      this.checkout.loading = true

      api
        .post('store/checkout')
        .then(response => {
          if (response.data.status=='success') {
            localStorage.removeItem('cart');
            this.$children[0].getCart()
            this.getCart()

            this.$router.push('/store/order/'+response.data.order.id);
          } else if (response.data.status=='inventory') {
            localStorage.removeItem('cart');
            this.$children[0].getCart()
            this.getCart()

            this.$noty.info(response.data.message)
          } else {
            this.$noty.error(response.data.message)
          }
        })
        .catch(error => {
          this.checkout.errored = error
          this.checkout.loading = false
          this.$noty.error('Não foi possível finalizar sua compra, tente novamente.')
        })
        .finally(() => {
          this.checkout.loading = false
        })
    }
  },
  mounted() {
    this.getCart()
  }
};
</script>

<template>
  <Layout>

    <div class="row">
      <div :class="[cart.items.total > 0 ? 'col-md-7 col-lg-8' : 'col-md-12']">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <vcl-list v-if="cart.items.loading" class="col-lg-6"></vcl-list>
              <div v-else-if="cart.items.total == 0" class="text-center py-5">
                <i class="far fa-sad-cry font-size-24"></i><br>
                seu carrinho está vazio
              </div>
              <table v-else class="table table-centered table-nowrap">
                <thead>
                  <tr>
                    <th class="border-0" colspan="2">Produto</th>
                    <th class="border-0 text-center">Qtd</th>
                    <th class="border-0 text-center">Preço</th>
                    <th class="border-0 text-center">Total</th>
                  </tr>
                </thead>
                <tbody v-if="cart.items.total > 0">
                  <tr v-for="(item,id) in cart.items.list" :key="id">
                    <td>
                      <img v-if="item.image" style="width:80px; height:80px; object-fit: cover;" class="" :src="item.image" />
                    </td>
                    <td class="font-weight-medium">
                      {{ item.name }}
                      <span v-if="item.inventory == 0" class="font-weight-normal font-size-12 text-danger text-uppercase">
                        <br>este produto não está mais disponível
                      </span>
                      <!-- <span v-else-if="item.quantity > item.inventory" class="font-weight-normal font-size-12 text-danger text-uppercase">
                        <br>restam apenas {{ item.inventory }} produto
                      </span> -->
                    </td>
                    <td class="text-center">
                      <Qty :id="id" :quantity="item.quantity"></Qty>
                    </td>
                    <td class="text-center">{{ item.price }}</td>
                    <td class="text-center">{{ item.subtotal }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div v-if="cart.items.total > 0" class="col-md-5 col-lg-4">
        <div class="card">
          <div class="card-body">
            <h5 class="mb-3">Prazo de Entrega</h5>
            <Shipping></Shipping>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="mb-3">Resumo do Pedido</h5>
            <div class="d-flex mb-2">
              <div class="flex-fill">
                <span v-if="cart.items.total <= 1">
                  {{ cart.items.total }} produto
                </span>
                <span v-else-if="cart.items.total > 1">
                  {{ cart.items.total }} produtos
                </span>
              </div>
              <div class="flex-fill text-right">
                {{ cart.subtotal }}
              </div>
            </div>
            <div class="d-flex">
              <div class="flex-fill">
                Frete
              </div>
              <div class="flex-fill text-right">
                {{ cart.shipping.value }}
              </div>
            </div>
            <hr>
            <div class="d-flex">
              <div class="flex-fill">
                <h5 class="mb-0">Total</h5>
              </div>
              <div class="flex-fill text-right">
                <h5 class="mb-0">{{ cart.total }}</h5>
              </div>
            </div>
            <hr>
            <button v-on:click="checkoutCart()" class="btn btn-success btn-block font-weight-medium text-uppercase py-2" :disabled="checkout.loading">
              <i class="bx bx-basket font-size-24 align-middle pb-1"></i>
              Finalizar Compra
              <b-spinner v-if="checkout.loading" small class="align-middle" variant="white" role="status"></b-spinner>
            </button>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>